.print-only {display: none;}
@media print {

  body.modal-open {
    overflow: visible !important;
    #wrapper {
      display: none;
    }
    
    // Wraper with no other styling
    .print-wrapper {
      display: none !important;
    }

    .modal.in {
      position: relative;
      
      .modal-dialog {
        width: 100%;
        margin: 0;

        .modal-content {
          border: none;
          display: block;

          .modal-header {
            .close {display: none;}
          } //.modal-header

          .modal-footer { display: none; }

          .modal-body {
            max-height: none;
            overflow: visible;
          } //.modal-body
        } //.modal-content
      } //.modal-dialog
    } //.modal.in
  }

  .print-only {
    display: unset;
  }
  .no-print {
    display: none;
  }

  a[href]:after {
    content: '' !important;
  }

  #wrapper {
    padding-left: 0;
  }

  #sidebar-wrapper, tr.search_row {
    display: none;
  }
}