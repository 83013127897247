.completed-panel {
  margin-right: 1.5em;
  margin-bottom: 0;
  min-height: calc((36px + (1.42857143em * 5))*2);
  height: 2vh;

  .completed-lots {
    tr.grouped {
      td, th {
        border-top: none;
      }
    }
    tr.no-sale {
      td, th {
        background-color: yellow;
      }
    }
  }
}