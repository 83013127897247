.clerking-grid {
  flex: 1 1 auto;
  margin-right: 1.5em;
  display: flex;
  flex-direction: column;

  input[type=number] {
    -moz-appearance:textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  tbody.active-lots::after {
    line-height: 1.25em;
    content: "\200C";
    display: table-row;
  }

  .primary-image {
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  tr.has-announcement {
    background-color: rgba(255, 0, 0, .25);
  }

  .title-2lines {
    max-height: 2.85714286em;
    overflow: hidden;
  }

  // .lot-actions {
  //   .btn + .btn {
  //     margin-left: 3px;
  //   }
  // }
}