.dropzone {
  min-height: auto;
  border: 1px solid #e7eaec !important;
  user-select: none;

  &.full-tab {
    border: 0 !important;
    padding: 0 !important;
  }

  .dropzone-previews {
    position: relative;
  }

  .dz-sort-help {
    display: block;
    margin: 0 0 10px 0;
  }

  .dz-progress-bar {
    margin-bottom: 15px;

    .progress {
      margin-bottom: 2px;
    }
  }

  .dz-message {
    cursor: pointer;
    margin: 0;
    padding: 2em;
    border: 1px dashed #e7eaec;
    border-radius: 10px;
  }

  &.disabled {
    .dz-message {
      display: none !important;
    }
    .dz-sort-help {
      display: none !important;
    }
    a.dz-options.dropdown-toggle {
      display: none !important;
    }
    #deleteAll {
      display: none !important;
    }
    .dz-icons-bl {
      .delete-file {
        display: none !important;
      }
    }
  }
  &.dz-started {
    .dz-message {
      display: block;
    }
  }

  &.dz-drag-hover {
    .dz-message {
      background-color: #e7eaec;
      opacity: 1;
    }
  }

  .dz-message:hover {
    background-color: #e7eaec;
    opacity: 1;
  }

  .dz-preview {
    margin: 0 20px 20px 0;
    background-color: transparent;

    .dz-image {
      width: 118px;
      background-color: #fff;
      border-radius: 10px;

      img { width: 100%; }
      i {
        position: absolute;
        margin: -25px 0 0 -25px;
        top: 50%;
        left: 50%;
        width: 51px;
        height: 51px;
        font-size: 51px;
        line-height: 51px;
        text-align: center;
        color: #696969;

        &.fa-file-pdf-o, &.fa-new-file-pdf { color: #B10302; }
        &.fa-file-word-o, &.fa-new-file-word { color: #D24726; }
        &.fa-file-excel-o, &.fa-new-file-excel { color: #207245; }
        &.fa-file-powerpoint-o, &.fa-new-file-powerpoint { color: #D24726; }
        &.fa-file-archive-o, &.fa-new-file-archive { color: #696969; }
        &.fa-spinner, &.fa-new-spinner { color: rgba(255, 255, 255, .4); }

        &.image-pending {
          top: unset;
          left: unset;
          bottom: 5px;
          right: 5px;
          width: 24px;
          height: 24px;
          font-size: 24px;
          line-height: 24px;
        }
      }
    }

    &.dz-image-preview {
      background-color: transparent;
    }

    &.ui-sortable-placeholder {
      background: #e7eaec;
      border: 1px dashed #e7eaec;
      border-radius: 10px;
    }

    &:hover {
      .dz-image img {
        transform: none;
        filter: none;
      }

      &.dz-error {
        .dz-error-message {
          opacity: 0;
          pointer-events: none;
        }
      }
    }

    &.dz-waiting {
      .dz-waiting-mark {
        opacity: 1;
        animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
      }
    }

    &.dz-complete {
      .dz-waiting-mark {
        opacity: 0;
        animation: slide-out 3s cubic-bezier(0.77, 0, 0.175, 1);
      }
    }

    &.dz-error {
      &.dz-preview-hover {
        .dz-error-message {
            opacity: 1;
            pointer-events: auto;
        }
      }
    }

    a {
      cursor: default;

      &[href] {
        cursor: pointer;
      }
    }

    .dz-progress {
      border: 0;
      background: rgba(255, 255, 255, 0.5);

      .dz-upload {
        background: #fff;
      }
    }

    .dz-success-mark, .dz-error-mark, .dz-waiting-mark {
      pointer-events: none;
      display: block;
      position: absolute;
      margin: -25px 0 0 -25px;
      top: 50%;
      left: 50%;
      width: 51px;
      height: 51px;
      opacity: 0;
      z-index: 500;

      i {
        margin: -25px 0 0 -25px;
        color: #fff;
        text-shadow: 0 0 3px rgba(0,0,0,.25);
      }
    }

    .dz-waiting-mark {
      i {
        animation: spin 2s linear infinite;
        transform-origin: 25px 25px;
      }
    }

    .dz-details {
      position: relative;
      padding: 8px 0 0 0;
      line-height: 1.2;
      opacity: 1;

      .dz-filename, .dz-size {
        display: block;
        margin: 0;
        font-size: inherit;

        span {
          background: transparent;
          border-radius: 0;
        }
      }

      .dz-filename:hover {
        span { border: 0; }
      }
      .dz-size {
        strong { font-weight: normal; }
      }
      span { padding: 0 !important; }
    }

    &:not(.dz-preview-with-menu) .dz-options {
      display: none;
      position: absolute;
      padding: 5px;
      top: 5px;
      right: 5px;
      color: #000;
      background: #fff;
      border-radius: 50px;
      box-shadow: 0 0 3px rgba(0, 0, 0, .25);
      z-index: 100;
    }

    .dz-icons {
      display: none;
      position: absolute;
      top: 5px;
      left: 5px;
      color: #000;
      z-index: 100;

      i {
        padding: 5px;
        background: #fff;
        border-radius: 50px;
        box-shadow: 0 0 3px rgba(0, 0, 0, .25);
      }
    }

    .dz-icons-bl {
      position: absolute;
      left: 5px;
      top: 90px;
      z-index: 100;

      a { cursor: pointer; }
      
      i {
        padding: 5px;
        background: #fff;
        border-radius: 50px;
        box-shadow: 0 0 3px rgba(0, 0, 0, .25);
      }
    }

    &.dz-success {
      .dz-options, .dz-icons {
        display: block;
      }
    }

    &.dropdown {
      &.open {
        a {
          &.dz-image, &.dz-filename {
            pointer-events: none;
          }
        }
      }
    }

    .dropdown-toggle { cursor: pointer; }
    .dropdown-menu {
      top: 35px;
      left: 6px;
      right: 6px;
      min-width: auto;

      li {
        padding: 0 4px;

        &:first-child { padding-top: 4px; }
        &:last-child { padding-bottom: 4px; }
      }

      a {
        cursor: pointer;
        margin: 0;
        padding: 0 5px;
        line-height: 23px;
      }

      i { margin-right: 5px; }
      .divider { margin: 0 }

      &:before, &:after {
        display: inline-block;
        position: absolute;
        content: '';
      }

      &:before {
        top: -7px;
        right: 3px;
        border-right: 7px solid transparent;
        border-bottom: 7px solid #ccc;
        border-left: 7px solid transparent;
        border-bottom-color: rgba(0, 0, 0, 0.2);
      }

      &:after {
        top: -6px;
        right: 4px;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #ffffff;
        border-left: 6px solid transparent;
        content: '';
      }
    }

    &.dz-preview-with-menu {
      .dz-options {
        position: absolute;
        padding: 5px;
        top: 5px;
        right: 5px;
        color: #000;
        background: #fff;
        border-radius: 50px;
        box-shadow: 0 0 3px rgba(0, 0, 0, .25);
        z-index: 1050;
      }
    }

    &.ui-sortable-helper {
      .dz-image {
        background-color: #fff;
        border-radius: 10px;
        width: 118px;
        height: 120px;
        position: relative;
        display: block;
        overflow: hidden;
        z-index: 1049;

        img { width: 100%; }
        i {
          position: absolute;
          margin: -25px 0 0 -25px;
          top: 50%;
          left: 50%;
          width: 51px;
          height: 51px;
          font-size: 51px;
          line-height: 51px;
          text-align: center;
          color: #696969;

          &.fa-file-pdf-o, &.fa-new-file-pdf { color: #B10302; }
          &.fa-file-word-o, &.fa-new-file-word { color: #D24726; }
          &.fa-file-excel-o, &.fa-new-file-excel { color: #207245; }
          &.fa-file-powerpoint-o, &.fa-new-file-powerpoint { color: #D24726; }
          &.fa-file-archive-o, &.fa-new-file-archive { color: #696969; }
        }
      }

      .dz-options {
        position: absolute;
        padding: 5px;
        top: 5px;
        right: 5px;
        color: #000;
        background: #fff;
        border-radius: 50px;
        box-shadow: 0 0 3px rgba(0, 0, 0, .25);
        z-index: 1050;
      }

      .dz-icons {
        position: absolute;
        top: 5px;
        left: 5px;
        color: #000;
        z-index: 1050;

        i {
          padding: 5px;
          background: #fff;
          border-radius: 50px;
          box-shadow: 0 0 3px rgba(0, 0, 0, .25);
        }
      }

      .dz-details {
        z-index: 1049;
        position: relative;
        padding: 8px 0 0 0;
        line-height: 1.2;
        opacity: 1;
        top: 0;
        left: 0;
        font-size: 13px;
        min-width: 100%;
        max-width: 100%;
        text-align: center;
        color: rgba(0, 0, 0, 0.9);
      }
    }
  }

  &.dz-single {
    .dropzone-previews { text-align: center; }
    .dz-preview { margin: 0 0 20px 0; }
  }
}