#wrapper {
  display: flex;

  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;

  padding-left: 80px;

  #sidebar-wrapper {
    z-index: 1001;
    position: fixed;
    // left: 250px;
    left: 0;
    width: 80px;
    flex-shrink: 0;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    background: $sidebar-background;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .page-content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

    #page-content-wrapper {
      flex: 1 1 auto;
    }
  }

  .sidebar-text {
    display: none;
  }

  &.toggled {
    #sidebar-wrapper {
      width: 250px;
    }

    .sidebar-text {
      display: inline;
    }
  }

  #page-content-wrapper {
    padding: 0 15px 15px;
  }

  .sidebar-nav {
    list-style: none;
    width: 250px;
    margin: 0 0 70px;
    padding: 0;

    & > .sidebar-brand {
      height: 140px;
      font-size: 18px;
      line-height: 60px;
      background: $sidebar-background;
      display: none;
      align-items: center;
      justify-content: center;

      img {
        max-height: 130px;
      }

      a {
        color: #999999;
        &:hover {
          color: #fff;
          background: none;
        }
      }
    }

    li {
      text-indent: 20px;
      line-height: 40px;

      a {
        display: block;
        text-decoration: none;
        color: #999999;

        &:hover {
          text-decoration: none;
          color: #fff;
          background: rgba(255,255,255,0.2);
        }

        &:active, &:focus {
          text-decoration: none;
        }

        i.fa-fw, i.fa-new-fw {
          text-indent: 0;
        }
      }

      & > ul {
        padding-left: 20px;
        list-style: none;
      }
    }
  }

  @media (min-width: 1200px) {
    padding-left: 250px;

    #sidebar-wrapper {
      width: 250px;
    }

    .sidebar-text {
      display: inline;
    }

    .sidebar-nav {
      & > .sidebar-brand {
        display: flex;
      }
    }

    &.toggled {
      padding-left: 80px;

      #sidebar-wrapper {
        width: 80px;
      }

      .sidebar-text {
        display: none;
      }
  
      .sidebar-nav {
        & > .sidebar-brand {
          display: none;
        }
      }
    }
  }
}

.navbar-header {
  float: none;

  @media(max-width: 992px) {
    #changePassword, div.logout {
      display: none;
    }
  }
}

.navbar-left,.navbar-right {
  float: none !important;
}
.navbar-toggle {
  display: block;
  cursor: pointer;
}
.navbar-collapse {
  border-top: 1px solid transparent;
  box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
}
.navbar-fixed-top {
  top: 0;
  border-width: 0 0 1px;
}
.navbar-collapse.collapse {
  display: none!important;
}
.navbar-nav {
  float: none!important;
  margin-top: 7.5px;
}
.navbar-nav>li {
  float: none;
}
.navbar-nav>li>a {
  padding-top: 10px;
  padding-bottom: 10px;
}
.collapse.in{
  display:block !important;
}

.modal-content {
  border-radius: 0px;
  background-clip: border-box;;
  border: 1px solid rgba(0,0,0,.2);
}