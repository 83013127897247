#inv-inspection-form .checklist {
  h3:first-child {
    margin-top: 0;
  }

  .checkpoint {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.form-group {
      margin-bottom: 0;
    }

    &.pad-top {
      align-items: flex-start;
      
      label {
        padding-top: 6px;
      }
    }

    + .checkpoint {
      margin-top: .25em;
    }

    label {
      margin-right: .5em;

      &:empty {
        margin-right: 40%;
      }
    }

    .select2-container {
      min-width: 175px;
    }
  }
}

#inspection_summary {
  h3 {
    margin-top: 10px;
  }

  .checkpoint {
    display: flex;
    align-items: flex-start;

    .checkpoint-label {
      font-weight: bold;
      margin-right: .25em;

      &::after {
        content: ':';
      }
    }
    .checkpoint-details {
      margin-left: .25em;

      &::before {
        content: ' - ';
      }
    }
  }
}