.checkbox input[type="checkbox"]:checked + label::after,
.checkbox input[type="radio"]:checked + label::after {
  font-family: "Font Awesome 5 Pro" !important;
  content: "\f00c" !important;
  font-weight: bold !important;
}

input[type="checkbox"].styled:checked + label:after,
input[type="radio"].styled:checked + label:after {
  font-family: 'Font Awesome 5 Pro' !important;
  content: "\f00c" !important;
  font-weight: bold !important;
}

.checkbox.checkbox-label-first {
  label {
    padding-left: 0;
    padding-right: 20px;
    float: none;

     &::before, &:after {
       left: auto;
       right: 0;
     }
  }
}

.checkbox, .radio {
  label:empty {
    padding-left: 0px;
  }
}

.checkbox.condensed {
  padding-left: 0;
  
  input[type=checkbox] {
    margin-left: 0;
  }

  label {
    &::before, &::after {
      margin-left: 0;
    }
  }
}