dl.similar-users {
  dt {
    float: left;
    margin-right: 10px;
    font-weight: normal;
    &::after {
      content: ':';
    }
  }
  dd {
    margin-left: 7em;
    &::after {
      content: '';
      display: block;
      clear: left;
    }
  }
}

#existing-users-list {
  height: calc((1.42857143em * 10) + 32px);
  font-size: 14px;
  padding: 6px 12px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  overflow-y: auto;

  .similar-user {
    cursor: default;

    & > * {
      overflow: hidden;
      max-height: 1.42857143em;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    

    &.selected {
      background-color: #5897fb;
      color: white;
    }

    &:not(.selected):hover {
      background-color: #ddd;
    }
  }
}