.live-clerking {
  padding: 1.5em;
  margin: 0;
  width: 100vw;
  height: 100vh;

  .auction-title, .ring-number { margin-top: 0; }
  
  & ~ #darkroom-icons {
    display: none;
  }

  & > .main-panel {
    height: calc(100vh - 3em - 36px);
  }

  $panel-back: #333;
  $panel-text: #fff;

  .panel {
    display: flex;
    flex-direction: column;
  }

  .panel-custom {
    border-color: $panel-back;
    min-height: 0;

    & > .panel-heading {
      color: $panel-text;
      background-color: $panel-back;
      border-color: $panel-back;
    }

    & > .panel-body.scroll {
      overflow-y: auto;
      max-height: calc(100% - 38px);

      table:only-child {
        margin: -15px;
        width: calc(100% + 30px);
        max-width: calc(100% + 30px);
      }
    }
  }

  & ~ #editBidModal {
    .modal-body {
      & > table {
        margin: -15px;
        width: calc(100% + 30px);
        max-width: calc(100% + 30px);
      }

      input[type=number] {
        -moz-appearance:textfield;
    
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
      }
    }

    tr.deleted {
      td:not(:last-child) {
        position: relative;

        &::after {
          position: absolute;
          content: ' ';
          left: 0;
          top: 50%;
          width: 100%;
          border-top: 1px solid #d9534f;
          z-index: 3;
        }
      }
    }
  }

  @import 'clerking-grid';
  @import 'review';
  @import 'completed-lots';
  @import 'upcoming-lots';
  @import 'master-clerking';
}

@import 'slideshow';
@import 'auctioneer';
@import 'ringman';
@import 'tiled-slideshow';