select.select2:not(.select2-hidden-accessible) {	
  max-height: 34px;	
}

select.form-control:not(.defined-width) + .select2-container {
  width: 100% !important;
}
.select2-container--default .select2-selection--single {
  height: 34px;
  border: 1px solid #ccc;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;

  .select2-selection__rendered {
    padding: 6px 20px 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
  }
  .select2-selection__arrow {
    height: 32px;
  }
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  min-height: 34px;
  border: 1px solid #ccc;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

.select2-container--default .select2-selection--multiple {
  min-height: 34px;
  border: 1px solid #ccc;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

// select.form-control.select2 + .select2-container {
//   width: 100% !important;
// }

// select.form-control.select2.hasError + .select2-container {
//   .select2-selection--single {
//     border-color: red;
//   }
// }

select.form-control {
  &:not(.defined-width) {
    &.select2, &.select2-modal {
      & + .select2-container {
        width: 100% !important;

        .input-group.flex-group & {
          width: 1% !important;
        }
      }
    }
  }

  &.select2, &.select2-modal {
    &.hasError + .select2-container {
      .select2-selection--single {
        border-color: #a94442;
      }
    }
  }
}

.select2-results {
  .option-group { font-weight: bold; }
  .option-child { padding-left: 15px; }

  .select2-create {
    padding: 6px;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    display: block;
    font-style: italic;
  }
}

select.select2:not(.normalcase) {
  & + .select2-container {

    .select2-selection__rendered {
      text-transform: uppercase;

      .select2-selection__clear {
        text-transform: lowercase;
      }
    }

  }
}
.select2-container.force-uppercase {
  .select2-results {
    .select2-results__option {
      text-transform: uppercase;
    }
  }
}

.input-group {
  .select2.form-control:first-child + .select2-container:not(:last-child) {
    .select2-selection--single {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .select2.form-control:last-child + .select2-container {
    .select2-selection--single {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &.flex-group {
    display: flex;
    .select2-container {
      flex: 1 1 auto;
    }

    .input-group-addon, .input-group-btn {
      width: auto !important;
    }
  }
}

.form-group-sm {
  .select2-container--default .select2-selection--single {
    height: 30px;
    border-radius: 3px;

    .select2-selection__rendered {
      font-size: 12px;
      line-height: 1.5;
      padding: 5px 20px 5px 10px;
    }
    .select2-selection__arrow {
      height: 26px;
    }
  } 
}

.select2-card-display {
  display: inline-flex;
  align-items: center;

  i.fa, i.far, i.fal, i.fab, i.fas {
    font-size: 1.5em;
    margin-right: .2em;
  }
}

select.select2-hidden-accessible.merge-commission_rates {
  & + .select2-container {
    .select2-selection--single {
      height: auto;

      tr:last-child {
        border-bottom: none;
      }
      .select2-selection__arrow {
        height: 100%;
      }
    }
  }
}

.show-invalid {
  select.select2:invalid + .select2-container {
    .select2-selection--single {
      border-color: #a94442;
    }
  }
}

select.select2.hidden + span.select2 {
  display: none !important;
}

.modal-footer {
  span.select2 {
    text-align: left;
  }
}

// special case width (width important doesn't copy over from select element)
#skipOptions + .select2 {
  width: 12em !important;
}