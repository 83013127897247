&.master-clerking {
  tr.lot.grouped {
    & > td, & > th {
      border-top: none;

      &:first-child {
        padding-left: 1.5em;
      }
    }
  }
}