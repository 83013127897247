.vertical-tabs {
  display: flex;

  .tab-pane > & {
    margin: -15px -10px;
  }

  .tab-content {
    flex: 1 1 auto;
  }

  .nav-tabs {
    border-bottom: none;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;

    & > li {
      & > a {
        margin-right: 0;
        margin-bottom: 2px;
      }
    }

    &:first-child {
      border-right: 2px solid #ddd;
      margin-right: 10px;

      & > li {
        & > a {
          &:after {
            left: auto;
            bottom: auto;
            top: 0;
            right: -2px;
            width: 2px;
            height: 100%;
          }
        }
      }
    }

    &:last-child {
      border-left: 2px solid #ddd;
      margin-left: 10px;

      & > li {
        & > a {
          &:after {
            left: -2px;
            bottom: auto;
            top: 0;
            width: 2px;
            height: 100%;
          }
        }
      }
    }

    
  }
}