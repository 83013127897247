$fa-font-path:   "/fonts";
$fa-css-prefix:  fa-new;

@import '@fortawesome/fontawesome-pro/scss/fontawesome.scss';
@import '@fortawesome/fontawesome-pro/scss/light.scss';
@import '@fortawesome/fontawesome-pro/scss/regular.scss';
@import '@fortawesome/fontawesome-pro/scss/solid.scss';
@import '@fortawesome/fontawesome-pro/scss/brands.scss';

.fa {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  font-style: normal;
}

$fa-css-prefix: fa;
@import '@fortawesome/fontawesome-pro/scss/v4-shims.scss';
.fa.fa-check:before {
  content: "\f00c";
}