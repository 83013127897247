.live-slideshow {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  position: absolute;


  .slide-panel {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    visibility: hidden;

    &.active {
      left: 0;
      visibility: visible;

      &.sliding {
        left: -100vw;
      }
    }

    &.next {
      left: 100vw;

      &.sliding {
        visibility: visible;
        left: 0;
      }
    }

    h2 {
      text-align: center;
      font-size: 72px;
    }

    .image {
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      position: relative;

      img {
        object-fit: contain;
        object-position: center center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  & ~ #darkroom-icons {
    display: none;
  }
}

.live-slideshow-intermission {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: white;
  opacity: 0;
  pointer-events: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  h2 { font-size: 8vw; }
  h3 { font-size: 15vw; }

  &.visible {
    opacity: 1;
    pointer-events: auto;
  }
}