.upcoming-panel {
  .panel-title {
    margin-right: 10px;
  }

  #quickActivate {
    flex: 1 1 auto;
    width: 2px;
  }

  .upcoming-lots {
    tr[data-passed]:not([data-passed="0"]) {
      background-color: #90d3fa;
    }
  }
}