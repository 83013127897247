$tree-line-color: black;
$tree-plusminus-color: black;
$tree-plusminus-background: white;

.treeview {
  user-select: none;

  ul, li {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  label {
    float: none;
    padding-left: 1.4em;
  }

  input {
    position: absolute;
    opacity: 0;
  }

  // ul:not(.dropdown-menu) > li > a:not(.btn) {
  //   color: #00f;
  //   text-decoration: none;

  //   &:hover {
  //     text-decoration: underline;
  //   }
  // }

  input + label ~ ul {
    margin: 0 0 0 22px;
  }

  input ~ ul { display: none; }

  label, label::before { cursor: pointer; }

  input:disabled + label {
    cursor: default;
    opacity: .6;
  }

  input:checked:not(:disabled) ~ ul {
    display: block;
  }

  label, ul:not(.dropdown-menu) > li > a:not(.btn), .leaf, label::before {
    display: inline-block;
    height: 2em;
    line-height: 2em;
    vertical-align: middle;
  }

  label::before {
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    font-style: normal;
    content: "\f0fe";
    width: 1em;
    background-color: $tree-plusminus-background;
    position: absolute;
    left: 0;
    height: 1em;
    z-index: 2;
    line-height: 1em;
    top: .5em;
    color: $tree-plusminus-color;
  }
  input:checked + label::before {
    content: "\f146";
  }
  
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    -webkit-animation: webkit-adjacent-element-selector-bugfix infinite 1s;
    @-webkit-keyframes webkit-adjacent-element-selector-bugfix {
      from { padding: 0; } 
      to { padding: 0; }
    }
  }

  /*
  88     88 88b 88 888888     .dP"Y8 888888 Yb  dP 88     888888 .dP"Y8 
  88     88 88Yb88 88__       `Ybo."   88    YbdP  88     88__   `Ybo." 
  88  .o 88 88 Y88 88""       o.`Y8b   88     8P   88  .o 88""   o.`Y8b 
  88ood8 88 88  Y8 888888     8bodP'   88    dP    88ood8 888888 8bodP' 
  */
  ul:not(.dropdown-menu) > li {
    position: relative;

    &::before {
      content: '';
      display: inline-block;
      border-left: 1px dotted $tree-line-color;
      height: 100%;
      width: 1px;
      position: absolute;
      top: .9em;
      margin-left: .35em;
      z-index: -2;
    }

    &:last-child::before {
      border-left: none;
    }
  }

  ul:not(.dropdown-menu) > li > a:not(.btn), .leaf {
    &::before {
      content: '';
      border-top: 1px dotted $tree-line-color;
      display: inline-block;
      height: .5em;
      width: .75em;
      margin-left: .5em;
      margin-right: .2em;
    }
  }
  label::before {
    margin-right: .2em;
  }

  li > ul > li:first-child > label > span::before {
    content: '';
    border-top: 1px dotted $tree-line-color;
    display: inline-block;
    height: .5em;
    width: 1em;
    margin-left: .5em;
    margin-right: .2em;
    position: absolute;
    left: -1.7em;
    top: .95em;
    z-index: -3;
  }
  ul:not(.dropdown-menu) > li:first-child {
    & > a:not(.btn), & > .leaf {
      margin-left: 1.4em;

      &::before {
        /*width: 2.45em;
        margin-left: -1.2em;*/
        border-top: none;
        border-bottom: 1px dotted $tree-line-color;
        border-left: 1px dotted $tree-line-color;
        position: absolute;
        left: .375em;
        top: 0;
        margin-left: 0;
        height: 1em;
      }
    }
  }

  .btn-group {
    margin-left: .5em;
  }
}

.categories {
  .treeview {
    li.deactivated {
      > label, > .leaf {
        color: #dc3545;
      }
    }
  }
}