$panel-back: #333;
$panel-text: #fff;

& {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;

  padding-left: 0;
  padding-right: 0;

  & > * {
    flex-shrink: 0;
  }

  .panel-scroll-area {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: scroll;
    padding-top: 20px;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
  }
}

#titleBar {
  display: flex;
  padding-left: 15px;
  padding-right: 15px;

  .title-details {
    flex-grow: 1;
    display: flex;
    align-items: center;
    min-width: 0;

    strong {
      font-size: 25px;
      max-height: 1.42857em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    span {
      white-space: nowrap;
    }
  }

  .title-actions {
    display: flex;
    align-items: center;

    > * {
      margin: 0 3px;
    }

    #btnReserve {
      color: #5d5f62;
      display: inline-block;
      font-weight: 400;
      text-align: center;
      vertical-align: middle;
      border: 1px solid #5d5f62;
      white-space: nowrap;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857;
      border-radius: 4px;
      cursor: move;
      z-index: 999999;
    }
  }
}

.panel {
  display: flex;
  flex-direction: column;
}

.panel-custom {
  border-color: $panel-back;
  min-height: 0;

  & > .panel-heading {
    color: $panel-text;
    background-color: $panel-back;
    border-color: $panel-back;
  }

  & > .panel-body.scroll {
    overflow-y: auto;
    max-height: calc(100% - 38px);

    table:only-child {
      margin: -15px;
      width: calc(100% + 30px);
      max-width: calc(100% + 30px);
    }
  }
}

tr.sortable {
  th {
    cursor: pointer;
    white-space: nowrap;
    
    &.sorted {
      &::after {
        display: inline-block;
        font: normal normal normal 14px/1 "Font Awesome 5 Pro";
        font-size: inherit;
        font-weight: 900;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\00a0\f0de";
      }
  
      &.desc::after {
        content: "\00a0\f0dd";
      }
    }
  }
}

tr.ui-draggable {
  cursor: move;
}

div.inv-item.ui-draggable {
  cursor: move;
}

#unassignedInv {
  max-height: 22.5em;
}

.well {
  background-color: white;

  &.selected {
    background-color: #3297fd !important;
  }
}

.well-dragging {
  opacity: .8;
}

.affix {
  top: 20px;
  position: sticky;
  z-index: 1; 
}

.seq-area .panel-body ul {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin: 0 0 5px 0;
    padding: 5px;
    display: flex;
    flex: 0 0 32%;
    position: relative;

    &.reserved-slot {
      background-color: #fcf8e3;
    }
    &.un-sequenced {
      color: #721c24;
      background-color: #bce6ff;
      border-color: #90d3fa;

      + .well:not(.un-sequenced) .inv-item {
        &::before {
          content: '';
          display: block;
          border-top: 3px solid #ddd;
          position: absolute;

          left: -16px;
          right: -16px;
          top: -12px;
        }
      }
    }

    .lot-num {
      text-align: right;
      width: 3em;
      margin-right: .25em;
    }
    .lot-num::after {
      content: ': ';
    }

    &.drag-over {
      &:not(.insert-after):not(.insert-before) {
        background-color: #c3c3c3;
      }
      
      &.insert-after::after {
        border-bottom: 1px solid black;
        content: '\00a0';
        position: absolute;
        top: calc(100% + 5px);
        height: 1px;
        left: 0;
        right: 0;
      }
      
      &.insert-before::before {
        border-top: 4px solid black;
        content: '\00a0';
        position: absolute;
        top: -5px;
        height: 1px;
        left: 0;
        right: 0;
      }
    }
  }
}

.seq-area {
  .list-header {
    padding-right: 5px;
    padding-left: 5px;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    font-weight: bold;
  }
  .inv-item {
    background-color: inherit;
    .title, .category {
      background-color: inherit;
      overflow: hidden;
      padding-right: 1em;
      position: relative;

      &::before {
        position: absolute;
        content: '\2026';
        bottom: 0;
        right: 0;
      }
      &::after {
        content: '';
        position: absolute;
        right: 0;
        width: 1em;
        height: 1em;
        background-color: inherit;
      }
    }

    .tooltip-inner {
      text-align: left;
    }
  }
  .list-header, .inv-item {
    display: flex;
    flex: 1 1 auto;

    & > *:not(.placeholder) {
      padding-left: 5px;
      padding-right: 5px;
    }

    .id_num { flex: 0 0 7em; }
    .thumbnail_image {
      flex: 0 0 48px;
      img {
        max-width: 38px;
        max-height: 38px;
      }
    }
    .consignor_code { flex: 0 0 8em; }
    .title, .category {
      flex: 1 1 50%;
      max-height: 2.8571em; 
      align-self: flex-start;
    }
    .vendor { flex: 0 0 155px; }
    .vin { flex: 0 0 195px; }
    .hours { flex: 0 0 65px; }
    .odometer { flex: 0 0 75px; }
    .date_rcvd { flex: 0 0 250px; }
    .ring { flex: 0 0 115px; }
    .qty { flex: 0 0 5em; }
    .btn-pad { flex: 0 0 30px; }
  }

  .placeholder {
    display: inline-flex;
    align-items: center;

    .checkbox {
      position: static;
      margin-top: 0;
      margin-bottom: 0;

      label {
        &::before {
          display: none;
        }
        &::after {
          font-family: "Font Awesome 5 Pro";
          content: "\f3c2" !important;
          font-weight: 700;
          cursor: pointer;
        }

      }
      input[type="checkbox"]:checked + label::after {
        content: "\f30d" !important;
      }
    }
  }

  .panel-body {
    flex-grow: 1;
  }

  &.insert-within {
    .panel-heading {
      background-color: #646464;
      color: white;
    }
    .panel-body {
      background-color: #969696;
    }
  }
}

& ~ .modal {
  #sortable-areas, .sortable-areas {
    list-style-type: none;
    margin: 0;
    padding: 0;
    min-height: 1.8em;
    border: 1px solid black;
    border-radius: 2px;

    li {
      margin: 0;
      padding: 0.4em;
      border: 1px solid #e5e5e5;
      background-color: white;

      &::before {
        font-family: 'Font Awesome 5 Pro';
        content: '\f142';
        margin-right: 3px;
      }
    }

    li + li {
      margin-top: 3px;
    }
  }
}

.tooltip.force-hide {
  display: none !important;
}