.control-label .btn {
  margin-left: 3px;
  margin-right: 3px;
  margin-bottom: 3px;
}

.btn {
  & > i.fa, & > i.far, & > i.fal, & > i.fas, & > i.fab {
    pointer-events: none;

    &:first-child:not(:last-child) {
      margin-right: 5px;
    }

    &:last-child:not(:first-child) {
      margin-left: 5px;
    }
//     &:first-child {
//       margin-left: -12px;
//       padding-left: 12px;
//     }
    
//     &:last-child {
//       margin-right: -12px;
//       padding-right: 12px;
//     }

//     &::before {
//       margin: 0 5px;
//     }
  }
}

.fa-stack.fa-stack-normal {
  width: 1em;
  height: 1em;
  line-height: 1em;

  .fa-stack-2x {
    font-size: 1.5em;
    left: -.10em;
    top: -.15em;
  }
}

.fa-stack.fa-stack-sidebar, .fa-new-stack.fa-stack-sidebar {
  width: 1.28571429em;
  line-height: 1em;
  height: 1em;
  margin-right: 5px;

  .fa, .fal, .far, .fas, .fab {
    width: 1.28571429em; // 20px
    text-align: center;
  }
  .fa-stack-2x, .fa-new-stack-2x {
    font-size: 1em;
    left: -1.2em;
  }
  .fa-stack-1x, .fa-new-stack-1x {
    font-size: .5em; //8px
    left: -2em;
  }
}

.fa-stack.fa-stack-title, .fa-new-stack.fa-stack-title {
  line-height: 25px;
  height: 25px;
  margin-right: 7px;
  margin-left: 0 !important;
  vertical-align: text-bottom;

  .fa, .fal, .far, .fab, .fas {
    text-align: center;
  }
  .fa-stack-2x, .fa-new-stack-2x {
    font-size: 25px !important;
  }
  .fa-stack-1x, .fa-new-stack-1x {
    font-size: 12.5px !important;
  }
}

.btn.btn-grey {
  color: #333;
  background-color: #ccc;
  border-color: #bdbcbc;

  &:hover {
    color: #fff;
    background-color: #686668;
    border-color: #5a585a;
  }
}

.btn-group.pad-delete {
  padding-right: 36px;
}

.btn-default.btn-seller-premium-override {
  border-color: #eea236;
}

.btn-default.btn-lot-premium-override {
  border-color: #d43f3a;
}

.btn-group.lbl-collapse {

  .btn .lvl-hide { display: none; }

  &.lvl-1 {

    .btn:first-child:nth-last-child(1),
    .btn:first-child:nth-last-child(1) ~ .btn {
      span.lvl-hide { display: inline; }
      div.lvl-hide { display: block; }
    }

  }

  &.lvl-2 {

    .btn:first-child:nth-last-child(2),
    .btn:first-child:nth-last-child(2) ~ .btn {
      span.lvl-hide { display: inline; }
      div.lvl-hide { display: block; }
    }

  }

  &.lvl-3 {

    .btn:first-child:nth-last-child(3),
    .btn:first-child:nth-last-child(3) ~ .btn {
      span.lvl-hide { display: inline; }
      div.lvl-hide { display: block; }
    }

  }

}

#titleBar, .titleBar {
  .title-option {
    .btn-link {
      font-size: 25px;
      padding: 0;
      color: black;

      .caret {
        margin-left: .25em;
        border-top-width: .25em;
        border-right-width: .25em;
        border-left-width: .25em;
      }
    }
  }
}

.form-group-sm {
  .btn {
    font-size: 12px;
    line-height: 1.5;
    padding: 5px 10px;
  }
}