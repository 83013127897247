.multi-live-slideshow {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  max-height: 100vh;

  ~ #darkroom-icons {
    display: none;
  }

  .lot-tile {
    position: relative;
    width: calc(var(--tile-width) - 10px);
    height: calc(var(--tile-height) - 10px);
    padding: 0;
    margin: 0;
    overflow: hidden;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
      vertical-align: middle;
    }

    .lot-number {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: .15em;
      font-size: 50px;

      background-color: #fff;
      color: #000;
      opacity: .75;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}