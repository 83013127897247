.auctioneer {

  .active-lots {

    .lot {
      position: relative;
      cursor: pointer;
      margin-right: 10px;
      width: 65px;
      height: 65px;

      img {
        max-width: 100%;
        max-height: 100%;
      }

      .lot-number {
        position: absolute;
        bottom: 0;
        left: 0; right: 0;
        padding: .15em;
        font-size: 18px;

        background-color: #fff;
        color: #000;
        opacity: .75;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .reserve-icon {
        position: absolute;
        top: 0; right: 0;
        font-size: 16px;
        padding: .2em;
        background: #d4edda;
        color: #155724;
        opacity: .75;
      }
    }
  }

  .panel:not(.fill) {
    flex-shrink: 0;
  }

  .selected-lot {
    .main-details {
      margin-right: 10px;
      margin-bottom: 20px;

      h1 { font-size: 48px; }
      h1, h3, h4 {
        margin-top: 0;
        margin-bottom: 0;
        white-space: nowrap;
      }

      .lot-image {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        border: 1px solid #333;
        border-radius: 4px;
      }

      .lien_amount {
        color: red;
        font-size: 21px;
      }
      .announcement:not(:empty) {
        padding: 5px;
        margin-left: 20px;
        border-radius: 4px;
        font-size: 35px;
        font-weight: bold;
        color: red;
      }
    }

    .data-info {
      width: 50%;
    }

    .finance-info {
      .reserve {
        font-size: 36px;
        color: red;
        font-weight: bold;
      }
      .cost {
        font-size: 36px;
        color: green;
        font-weight: bold;
      }
      .absentee_bid {
        font-size: 36px;
        color: blue;
        font-weight: bold;
      }
      .estimate_low, .estimate_high {
        font-size: 21px;
      }
    }

    .lot-details {
      font-size: 1.5em;
      .descriptions {
        margin-top: 10px;
        text-transform: uppercase;
      }
    }
  }

}