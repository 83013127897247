.review {
  flex: 0 0 250px;
  display: flex;
  flex-direction: column;

  & > .panel {
    flex: 1 1 auto;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @import 'upcoming-lots';
}