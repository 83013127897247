.modal-dialog {
  &.modal-fill {
    width: 90%;
  }

  &.modal-autocheck {
    .modal-body {
      height: 80vh;
      padding: 0;
      overflow-y: hidden !important;

      iframe {
        width: 100%;
        height: 100%;
        border: 0;
      }
    }
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    border-radius: 6px;

    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;

      &::before, &::after {
        content: none;
      }

      & > button:first-child {
        order: 2;
      }
    }

    .modal-body {
      flex: 1 1 auto;
      max-height: calc(100vh - 210px);
      overflow-y: auto;
      padding-bottom: 20px;

      .modal-header {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      &.step-container {
        .wait-step {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: white;
          opacity: 0.75;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 9999;
        }
      }

      &.tabbed-modal {
        padding: 0;
      
        .tab-content {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }

    .modal-footer {
      display: flex;
      justify-content: center;
      align-items: center;

      .left { margin-right: auto; }
      .right { margin-left: auto; }
      .btn { margin: 0 5px; }

      .btn-group.split-button {
        .btn:first-child {
          margin-right: 0;
        }
        .btn + .btn.dropdown-toggle {
          margin-left: 0;
        }
      }

      &::before, &::after {
        content: none;
      }

      & > *:first-child:not(.left):not(.right) {
        margin-left: auto;
      }

      .left, .right {
        .checkbox label::after {
          padding-left: 0;
          text-align: center;
        }
      }
    }
  }
}

// .modal-open {
//   overflow-y: auto;
//   &.no-overflow {
//     overflow-y: hidden;
//   }
// }