#itemList {
  .activate-item {
    display: none;
  }
  .inactive {
    color: #FF0000;
    .activate-item {
      display: block;
    }
    .deactivate-item {
      display: none;
    }
    .transfer-item {
      display: none;
    }
  }
}