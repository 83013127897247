$qb-line-color: black;

.query-builder {
  user-select: none;
  // line-height: .8em;

  .select2.inline-button + .select2-container {
    .select2-selection--single {
      border: none;
      box-shadow: none;

      .select2-selection__rendered,
      .select2-selection__placeholder {
        color: #000;
      }

      .select2-selection__arrow b {
        border-color: #000 transparent transparent transparent;
      }
    }

    &.select2-container--open {
      .select2-selection--single {
        .select2-selection__arrow b {
          border-color: transparent transparent #000 transparent;
        }
      }
    }
  }
  
  ul, li {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  ul.conditions > li {
    padding-bottom: 8px;
  }

  em {
    float: none;
    padding-left: .4em;
  }

  // ul:not(.dropdown-menu) > li > a:not(.btn) {
  //   color: #00f;
  //   text-decoration: none;

  //   &:hover {
  //     text-decoration: underline;
  //   }
  // }

  em ~ ul {
    margin: 0 0 0 22px;
  }

  em, ul:not(.dropdown-menu) > li > a:not(.btn) {
    display: inline-block;
    height: 2em;
    line-height: 2em;
    vertical-align: middle;
  }

  @media screen and (-webkit-min-device-pixel-ratio:0) {
    -webkit-animation: webkit-adjacent-element-selector-bugfix infinite 1s;
    @-webkit-keyframes webkit-adjacent-element-selector-bugfix {
      from { padding: 0; } 
      to { padding: 0; }
    }
  }

  /*
  88     88 88b 88 888888     .dP"Y8 888888 Yb  dP 88     888888 .dP"Y8 
  88     88 88Yb88 88__       `Ybo."   88    YbdP  88     88__   `Ybo." 
  88  .o 88 88 Y88 88""       o.`Y8b   88     8P   88  .o 88""   o.`Y8b 
  88ood8 88 88  Y8 888888     8bodP'   88    dP    88ood8 888888 8bodP' 
  */
  ul:not(.dropdown-menu):not(.dr-day-list):not(.dr-days-of-week-list):not(.dr-preset-list):not(.list-unstyled) {
    & > li {
      position: relative;
  
      &::before {
        content: '';
        display: inline-block;
        border-left: 1px dotted $tree-line-color;
        height: 100%;
        width: 1px;
        position: absolute;
        top: .9em;
        margin-left: .35em;
        z-index: -2;
      }
  
      &:last-child::before {
        border-left: none;
      }
    }
  }

  .dr-day, .dr-day-of-week {
    &::before {
      border-left: none;
    }
  }
  
  .date {
    & > * > ul > li {
      &::before {
        border-left: none;
      }
    }
  }

  ul:not(.dropdown-menu) > li > a:not(.btn), .condition {
    &::before {
      content: '';
      border-top: 1px dotted $tree-line-color;
      display: inline-block;
      height: .5em;
      width: .75em;
      margin-left: .5em;
      margin-right: .2em;
    }
  }
  label::before {
    margin-right: .2em;
  }

  li > ul > li:first-child > label > span::before {
    content: '';
    border-top: 1px dotted $tree-line-color;
    display: inline-block;
    height: .5em;
    width: 1em;
    margin-left: .5em;
    margin-right: .2em;
    position: absolute;
    left: -1.7em;
    top: .95em;
    z-index: -3;
  }
  ul:not(.dropdown-menu) > li:first-child {
    & > a:not(.btn), & > .condition {
      margin-left: 1.4em;

      &::before {
        /*width: 2.45em;
        margin-left: -1.2em;*/
        border-top: none;
        border-bottom: 1px dotted $tree-line-color;
        border-left: 1px dotted $tree-line-color;
        position: absolute;
        left: .375em;
        top: 0;
        margin-left: 0;
        height: 1em;
      }
    }
  }

  .conditions > li:first-child {
    &::before {
      border-left: none;
      border-bottom: none;
    }
  }

  .btn-group {
    margin-left: .5em;
  }

  .condition-field,
  .condition-operator,
  .condition-value {
    display: inline-block;
    padding-right: 10px;
    padding-left: .4em;
  }

  span.select2 .select2-selection ul > li::before {
    content: none;
  }
}