/* * { font-size: 10px; } */

.html {
  padding: 0 3px;

  & > p {
    &:first-child { margin-top: 0; padding-top: 0; }
    &:last-child { margin-bottom: 0; padding-bottom: 0; }
  }
}

.section {
  border-bottom: 2px solid #979797;
  margin-bottom: 7px;
  padding-bottom: 7px;
}

.logo { width: 100px; height: auto; }
.invoice-details {
  background-color: #ccc;
  min-height: 5em;
  padding: 10px;
  color: #686668;
}

h3 {
  font-weight: bold;
  font-size: 1.15em;
}

.info-dl dl {
  dt { float: left; margin-right: 10px; font-weight: normal; }
  dd {
    margin-left: 7em;
    &::after {
      content: '';
      display: block;
      clear: left;
    }
  }
}

ul.exemptions {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin: 0;
    padding: 0;
  }
}

table.table.lots {
  margin-bottom: 5px;

  .lot { width: 25px; }
  .tax-class { width: 75px; }
  .qty { width: 15px; }
  .bid { width: 55px; }
  .sale { width: 55px; }
  .line-premium { width: 55px; }
  .line-tax { width: 55px; }
  .line-total { width: 70px; }

  & > tbody {
    & > tr {
      &.expense_row {
        & > td, & > th {
          border-top: none;
        }
      }
      &.grouped_row {
        & > td, & > th {
          border-top: none;

          &:first-child {
            padding-left: 1.5em;

            .button-container {
              .buttons {
                right: calc(100% + 2px + 1.5em);
              }
            }
          }
        }
      }
    }
  }
  
  & > tfoot {
    & > tr {
      & > td, & > th {
        border-top: 3px double #979797;
        border-bottom: 2px solid #979797;
      }
    }
  }
}

td, th {
  &:first-child {
    .button-container {
      position: relative;
      overflow: visible;
      height: 0;

      .buttons {
        position: absolute;
        right: calc(100% + 5px);
        top: calc(-1.5em - 2px);
        white-space: nowrap;
        text-align: right;

        .btn + .btn {
          margin-left: 5px;
        }
      }
    }
  }

  &:last-child {
    .button-container {
      position: relative;
      overflow: visible;
      height: 0;

      .buttons {
        position: absolute;
        left: calc(100% + 5px);
        top: calc(-1.5em - 2px);
        white-space: nowrap;
        text-align: left;

        .btn + .btn {
          margin-left: 5px;
        }
      }
    }
  }
}

table.amounts {
  width: auto;

  & > tbody {
    & > tr {
      & > td {
        border-top: none;
        &.bottom-border {
          padding: 0;
          border-bottom: 1px solid #979797;
        }
      }

      &.payment-has-memo {
        td {
          padding-bottom: 0;
        }
      }
      &.payment-memo {
        td {
          padding: 0 0 0 10px;
          font-size: .85em;
        }
      }
    }
  }
}

.watermark {
  padding-left: 40px;

  .paid-in-full {
    border: 1px solid #900;
    color: #900;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
    font-size: 15px;
  }
}

#pageFooter {
  margin-top: 15px;
}

table.table.table-condensed.deposits {
  width: auto;

  .deposit-used {
    td {
      text-decoration: line-through;
    }
  }
}