@mixin alert-is-link-variant($text-color) {
  color: $text-color;
  &:hover {
    color: darken($text-color, 10%);
  }
}
a.alert {
  display: block;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 10px;

  &:hover {
    font-weight: bold;
  }

  &.alert-success {
    @include alert-is-link-variant($alert-success-text);
  }
  &.alert-info {
    @include alert-is-link-variant($alert-info-text);
  }
  &.alert-warning {
    @include alert-is-link-variant($alert-warning-text);
  }
  &.alert-danger {
    @include alert-is-link-variant($alert-danger-text);
  }
}