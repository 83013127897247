.btn-primary {
  background-color: $button-background;
  border-color: $button-background;
  color: $button-foreground;

  &:hover {
    background-color: $button-hover-background;
    color: $button-hover-foreground;
  }
}

.btn-primary-outline {
  color: $button-background;
  border-color: $button-background;

  &:hover {
    color: $button-hover-background;
    border-color: $button-hover-background;
  }
}

.btn-danger-outline {
  color: #d9534f;
  background-color: transparent;
  background-image: none;
  border-color: #d9534f;
  &:hover {
    color: #c9302c;
    border-color: #ac2925;
  }
}

.pagination {
  margin: 0 !important;
  
  & > li {
    & > a, & > span {
      &, &:focus, &:hover {
        color: $button-background;
      }
    }
  }
  & > .active {
    & > a, & > span {
      &, &:focus, &:hover {
        background-color: $button-background;
        border-color: $button-background;
      }
    }
  }

  &.page-size-small {
    & > li {
      & > a, & > span {
        padding: 3px 9px;
      }
    }
  }
}

.table {
  &.auto-width { width: auto; }
  
  tbody {
    tr {
      td, th { vertical-align: middle; }
    }
  }

  .td, .th {
    background-color: #fff;
  }
  .th {
    font-weight: bold;
    text-align: center;

    &.text-left {
      text-align: left;
    }
  }

  & > .thead, & > .tbody {
    & > .tr {
      & > .th, & > .td {
        padding: 8px;
        line-height: 1.42857143;
        vertical-align: top;
        border-top: 1px solid #ddd;
      }
    }
  }

  & > .tbody {
    border-top: 2px solid #ddd;
  }

  & > .thead {
    & > .tr {
      & > .th {
        vertical-align: bottom;
      }
    }
  }

  & > .tbody + .tbody {
    border-top: 2px solid #ddd;
  }
}

.table-striped {
  & > .tbody {
    & > .tr:nth-of-type(odd) {
      & > .td, & > .th {
        background-color: #f9f9f9;
      }
    }
  }
}

.btn {
  & > i.fa.left, & > i.far.left, & > i.fal.left, & > i.fas.left, & > i.fab.left {
    margin-right: 5px;
  }
  & > i.fa.right, & > i.far.right, & > i.fal.right, & > i.fas.right, & > i.fab.right {
    margin-left: 5px;
  }

  /*.dropdown-toggle {
    .caret {
      margin-left: 5px;
    }
  }*/
}

i.fa.left, i.far.left, i.fal.left, i.fas.left, i.fab.left {
  margin-right: 5px;
}
i.fa.right, i.far.right, i.fal.right, i.fas.right, i.fab.right {
  margin-left: 5px;
}

.dropdown-menu:not(.dz-menu) {
  a > i.fa, a > i.far, a > i.fal, a > i.fas, a > i.fab {
    margin-left: -10px;
  }
}

.btn-link.no-padding {
  padding: 0;
  line-height: 1em;
}

.row-tight {
  &.row, & .row {
    margin-left: -5px;
    margin-right: -5px;
  }

  & [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.row.vertical-align {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & > [class^="col-"], & > [class*=" col-"] {
    &[class^="valign-"], &[class*=" valign-"] {
      display: flex;
    }
    &.valign-top { align-items: flex-start; }
    &.valign-center { align-items: center; }
    &.valign-bottom { align-items: flex-end; }
    &.valign-left { justify-content: flex-start; }
    &.valign-right { justify-content: flex-end; }
    &.valign-hcenter { justify-content: center; }
  }
}

label .help-block {
  display: inline;
  margin-left: 5px;
  font-weight: normal;
}

.control-label {
  a {
    margin-left: .25em;
    font-weight: normal;

    color: $link-color;
    text-decoration: none;

    &:hover,
    &:focus {
      color: $link-hover-color;
      text-decoration: $link-hover-decoration;
    }

    &:focus {
      @include tab-focus;
    }
  }
}

.table thead tr.search_form th, .table thead tr.search_row th {
  padding: 0;
  font-weight: normal;
}

th[data-sortcolumn] {
  cursor: pointer;
  white-space: nowrap;
  
  &.sorted {
    &::after {
      display: inline-block;
      font: normal normal normal 14px/1 "Font Awesome 5 Pro";
      font-size: inherit;
      font-weight: 900;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\00a0\f0de";
    }

    &.desc::after {
      content: "\00a0\f0dd";
    }
  }
}

.tab-content.no-padding { padding: 0; }

.button-block {
  button {
    display: block;
  }

  button + button {
    margin-top: 5px;
  }
}

.table-striped {
  .datepicker table {
    tr {
      border-bottom: none;
    }
  }
}

fieldset.bordered {
  border: 1px groove #ddd;
  padding: 0 14px 0 14px;
  margin: 0 0 1.5em 0;
  box-shadow: 0px 0px 0px 0px #000;

  & > legend:first-child {
    font-size: 14px;
    font-weight: bold;
    padding: 0 5px;
    width: auto;
    border-bottom: none;
  }
}

.btn-group {
  .btn + .btn.dropdown-toggle {
    border-left-color: #313335;

    &.btn-xs {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .btn-active-success {
    color: #333;
    background-color: #fff;
    border-color: #ccc;

    &:hover {
      color: #fff;
      background-color: #5cb85c;
      border-color: #4cae4c;
    }

    &.active {
      color: #fff;
      background-color: #449d44;
      border-color: #398439;

      &:hover {
        background-color: #398439;
        border-color: #255625;
      }
    }
  }
}

.btn-grp {
  & > .btn + .btn, & > .btn + .btn-group, & > .btn-group + .btn,
  & > .btn-group + .btn-group,
  & > .btn.dropdown-toggle + .dropdown-menu + .btn,
  & > .btn.dropdown-toggle + .dropdown-menu + .btn-group {
    margin-left: .5em;
  }
}

.dropdown-menu > li > a.text-danger {
  color: #a94442;

  &:focus, &:hover {
    color: #843534;
  }
}

.dropdown-menu > li > a.text-info {
  color: #31708f;

  &:focus, &:hover {
    color: #245269;
  }
}

.flex-form-labels {
  label {
    min-height: 34px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 0;
    display: block;
  }
}

.tab-content-border {
  &.nav.nav-tabs {
    margin-bottom: 0;
  }

  &.tab-content {
    border-width: 0 2px 2px 2px;
    border-style: solid;
    border-color: #ddd;
    padding: 0 10px;
  }
}

ul.dropdown-menu {
  li {
    a {
      i.fa, i.far, i.fal, i.fas, i.fab {
        margin-right: 5px;
      }
    }
  }
}

a { cursor: pointer; }

.form-group.form-group-sm {
  margin-top: -10px;

  .control-label {
    font-size: 12px;
  }
}

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

@function breakpoint-min($name) {
  $min: map-get($grid-breakpoints, $name);
  @return if($min != 0, $min, null);
}

@function breakpoint-infix($name) {
  @return if(breakpoint-min($name) == null, "", "-#{$name}");
}

@mixin each-grid-columns($columns) {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint);
    @for $i from 1 through $columns {
      .col#{$infix}-#{$i} {
        @content;
      }
    }
  }
}

@mixin make-col($size, $columns) {
  flex: 0 0 percentage($size / $columns);
  max-width: percentage($size / $columns);
}

@mixin media-breakpoint-up($name) {
  $min: breakpoint-min($name);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

.row.flex-stretch {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;

  @include each-grid-columns(12) {
    float: none;
  }

  @each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint);
    @for $i from 1 through 12 {
      .col#{$infix}-#{$i} {
        flex: 1 0 100%;
        max-width: 100%;
      }
    }

    @include media-breakpoint-up($breakpoint) {
      @for $i from 1 through 12 {
        .col#{$infix}-#{$i} {
          @include make-col($i, 12);
        }
      }
    }
  }
  
}

ul.dropdown-menu.fixed {
  position: fixed;
  z-index: 50;
}

.dropright.open > .dropdown-menu {
  left: auto;
  right: 0;
}