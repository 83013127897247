.flex {
  display: flex;

  &.h-center { justify-content: center; }
  &.h-spread { justify-content: space-around; }
  &.h-right { justify-content: flex-end; }
  &.split { justify-content: space-between; }
  &.v-center { align-items: center; }
  &.v-stretch { align-items: stretch; }
  &.v-bottom { align-items: flex-end; }
  &.vertical { flex-direction: column; }
  &.wrap { flex-wrap: wrap; }

  &.stretch { align-items: stretch; }
  &.grow-childs > * { flex-grow: 1; }
  
  &.col-margin.neg-col {	
    margin-left: -15px;	
    margin-right: -15px;	
  }
  &.col-margin > * { padding-left: 15px; padding-right: 15px; }
  &.row-margin > * { margin-bottom: 15px; }

  .fill {
    flex: 1 1 auto;
  }

  &.vertical > .fill {
    min-height: 0;
  }

  .shrink {
    flex: 0 1 auto;
  }

  .fill-no-shrink {
    flex: 1 0 auto;
  }

  & > .v-stretch { align-self: stretch; }
  & > .v-top { align-self: flex-start; }
  & > .v-bottom { align-self: flex-end; }
  & > .v-center { align-self: center; }

  .colxs-8 { width: 66.66666667%; }
  .colxs-5 { width: 20%; }
  .colxs-4 { width: 25%; }
  .colxs-3 { width: 33.33333333%; }
  .colxs-2 { width: 50%; }

  .bs-col-xs-12 { width: 100%; }
  .bs-col-xs-11 { width: 91.66666667%; }
  .bs-col-xs-10 { width: 83.33333333%; }
  .bs-col-xs-9 { width: 75%; }
  .bs-col-xs-8 { width: 66.66666667%; }
  .bs-col-xs-7 { width: 58.33333333%; }
  .bs-col-xs-6 { width: 50%; }
  .bs-col-xs-5 { width: 41.66666667%; }
  .bs-col-xs-4 { width: 33.33333333%; }
  .bs-col-xs-3 { width: 25%; }
  .bs-col-xs-2 { width: 16.66666667%; }
  .bs-col-xs-1 { width: 8.33333333%; }

  &.colmd-2 > *, &.colmd-3 > *, &.colmd-4 > * {
    width: 100%;
  }

  .ml-auto {
    margin-left: auto;
  }

  @media (min-width: 992px) {
    &.colmd-4 > * {
      width: 25%;
    }
    &.colmd-3 > * {
      width: 33.33333333%;
    }
    &.colmd-2 > * {
      width: 50%;
    }
  }

  @media (min-width: 768px) {	
    .bs-col-sm-2 { width: 16.66666667%; }	
  }

  @media (max-width: 767.995px) {	
    .fill-sm { width: 100%; }	
  }
}

.flex-jcenter { justify-content: center; }