label {
  margin-bottom: 0;
  position: relative;

  &.input-group-addon {
    float: none;
  }

  a:only-child {
    color: inherit;
  }

  &.after {
    float: none;
    margin-left: .5em;
  }

  &.nostyle {
    font-weight: normal;
  }

  .help-text {
    margin-left: .4em;
    font-size: .8em;
    font-weight: normal;
    font-style: italic;
  }
}

.form-group, .form-group-sm {

  &.required {
    & > label:not(.input-group-addon)::after {
      content: '*';
      color: red;
      position: absolute;
      font-size: 1em;
      padding-left: .1em;
      top: -.2em;
    }
  }

  .input-group {
    width: 100%;
  }
}

.form-inline {
  .form-group {
    .input-group {
      width: auto;
    }
  }
}

.flex-group-auto > .form-group {
  .input-group {
    width: auto;
    display: inline-table;

    .form-control {
      width: auto;
    }
    .input-group-addon {
      width: auto;
    }
  }
  label.stacked {
    float: none;
    display: block;
  }

  & + .form-group {
    margin-left: 15px;
  }
}

// .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
//   &.col-sm-pad {
//     padding-right: 5px;
//     padding-left: 5px;
//   }
// }

.form-control-static {
  width: 100%;
  display: inline-block;

  &:not(.normalcase) {
    text-transform: uppercase;
  }

  .input-group & {
    display: table-cell;
  }
}


.has-feedback label~.form-control-feedback {
  top: 20px;
}

label.switch {
  cursor: pointer;
  margin: 0;
  padding: 3px 0 10px 0;

  .form-group.disabled & {
    cursor: default;
  }
}

// .hz-switch-group {
//   padding-top: 1em;
//   > .form-group + .form-group {
//     margin-left: .5em;
//   }
// }
.switch-group {
  display: flex;

  &:not(.switch-group-vertical) {
    align-items: center;
    align-content: center;
    min-height: 34px;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;

    .form-group {
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 15px;
    }

    .form-group-sm > & .form-group {
      margin-bottom: 0;
    }

    // &.form-group {
    //   .form-group {
    //     margin-bottom: 0;
    //     padding-left: 0;
    //     padding-right: 0;
    //     height: calc(34px + 1em);

    //     .switch {
    //       align-self: center;
    //     }
    //   }
    // }
    
    // .form-group + .form-group, .form-group:not(:first-child) {
    //   margin-left: 2em;
    // }
  }

  .form-group > & {
    margin-left: 0;
    margin-right: 0;
    min-height: 34px;

    & > .form-group:first-child {
      padding-left: 0;
    }
    & > .form-group:last-child {
      padding-right: 0;
    }
  }

  .form-group-sm > & {
    margin-left: 0;
    margin-right: 0;
    height: 30px;
    min-height: 30px;

    & > .form-group {
      height: 30px;
    }

    & > .form-group:first-child {
      padding-left: 0;
    }
    & > .form-group:last-child {
      padding-right: 0;
    }
  }

  &.mg-bot {
    margin-bottom: 15px;
  }

  .form-group {
    display: inline-flex;
    margin-bottom: 0;

    label.switch {
      padding: 0;
      align-self: center;
    }
  }

  label + .switch, .switch + label { margin-left: .5em; }
  & > label {
    padding: 0;
  }

  &.switch-group-vertical {
    flex-direction: column;

    .form-group + .form-group {
      margin-top: 15px;
    }
  }
}

.disabled-switch {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  line-height: 0;
  margin: 0 5px;
  align-self: center;

  &.checked {
    background-color: lighten($sidebar-background, 30%);
    padding-left: 15px;

    &::after {
      content: '';
      display: inline-block;
      padding: 12px 0;
      background-color: $grey-color;
      text-align: center;
      width: 17px;
      margin-left: 15px;
    }
  }
  &:not(.checked) {
    background-color: darken($grey-color, 20%);
    padding-right: 15px;

    &::before {
      content: '';
      display: inline-block;
      padding: 12px 0;
      background-color: $grey-color;
      text-align: center;
      width: 17px;
      margin-right: 15px;
    }
  }
}
div.switch {
  @include user-select(none);
  line-height: 0;

  input { opacity: 0; overflow: hidden; visibility: hidden; height: 0; width: 0; }

  label {
    cursor: pointer;
    display: inline-block;
    margin: 0;
    width: 64px;
    overflow: hidden;
    background-color: $grey-color;
    font-size: 12px;
    text-align: left;
  }

  span {
    display: block;
    width: 200%;
    white-space: nowrap;
    @include transition(margin 0.3s ease-in 0s);

    &::before, &::after {
      display: inline-block;
      padding: 12px 0;
      color: #fff;
      text-align: center;
      width: 35%;
      line-height: 0;
    }

    &::before {
      margin-left: -35%;
      background-color: $sidebar-background;
      content: attr(data-on);
    }

    &::after {
      margin-left: 15%;
      background-color: darken($grey-color, 30%);
      content: attr(data-off);
    }
  }

  input:checked + label span { margin-left: 71%; }
}

.not-checkbox-inline {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  vertical-align: middle;

  .checkbox-inline + &, & + .checkbox-inline {
    margin-top: 0;
    margin-left: 10px;
  }
}

.form-group {
  .mce-tinymce {
    border-radius: 4px;
    .mce-toolbar-grp {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    .mce-edit-area, .mce-edit-area > iframe {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

.form-group.form-group-inline {
  display: inline-block;
  
  & + .form-group-inline {
    margin-left: 10px;
  }
}

.has-error {
  .mce-tinymce,
  .select2-container--default .select2-selection--single,
  .select2-container--default .select2-selection--multiple {
    border-color: $error-background;
  }

  label.switch {
    color: $error-background;
  }

  .input-group-btn {	
    .btn {	
      border-top-color: $error-background;	
      border-bottom-color: $error-background;	
    }	
     &:first-child {	
      .btn {	
        border-left-color: $error-background;	
      }	
    }	
     &:last-child {	
      .btn {	
        border-right-color: $error-background;	
      }	
    }	
  }
  
  .input-group {
    .input-group-btn:first-child {
      .btn {
        border-right-color: $error-background;
      }
    }
    .input-group-btn:last-child {
      .btn {
        border-left-color: $error-background;
      }
    }
  }
}

.tooltip.has-error {
  .tooltip-inner {
    background-color: $error-background;
  }

  &.top .tooltip-arrow { border-top-color: $error-background; }
  &.top-left .tooltip-arrow { border-top-color: $error-background; }
  &.top-right .tooltip-arrow { border-top-color: $error-background; }
  &.right .tooltip-arrow { border-right-color: $error-background; }
  &.left .tooltip-arrow { border-left-color: $error-background; }
  &.bottom, &.bottom-left, &.bottom-right { border-bottom-color: $error-background; }
}

.permissions {
  .form-group {
    margin-bottom: 5px;
    
    .checkbox {
      margin-top: 5px;
      margin-bottom: 5px;

      &:before, &:after { display: table; content: " "; }
      &:after { clear: both;}
    }

    & + .form-group {
      .checkbox { margin-top: 0; }
    }
  }
}

.form-control-static.input {
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #eee;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  cursor: not-allowed;

  &.expandable {
    height: auto;
    min-height: 34px;
  }
}

.form-group-sm {
  .form-control-static.input {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;

    &.expandable {
      height: auto;
      min-height: 30px;
    }
  }

  .input-group-btn {
    .btn {
      height: 30px
    }
  }
}

.input-group {
  .form-control-static.input:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .form-control-static.input:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.form-inline .form-group-horizontal, .form-group-horizontal {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  
  & > label {
    margin-right: 5px;
  }
}

form.waiter {
  position: relative;

  &.waiting::after {
    content: 'Waiting';
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    background: white;
    opacity: .8;
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.waiting[data-wait-msg]::after {
    content: attr(data-wait-msg);
  }
}

.flex.h-center .checkbox {
  margin-right: -5px;
}

.input-group-help {
  display: flex;
  width: 100%;

  .form-control {
    flex-grow: 1;
  }
}

.hidden-file {
  position: absolute;
  top: -999em;
}

.select2-hidden-accessible {
  display: none !important;
}