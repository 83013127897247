.context-menu {
  font-size: 1.1em;
  position: absolute;
  width: 200px;
  height: auto;
  padding: 5px 0px;
  border-radius: 5px;
  top: 10;
  left: 10;
  background-color: $button-background;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24);
  color: $button-foreground;
  z-index: 10;

  .context-item {
    height: 32px;
    line-height: 32px;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0 10px;

    &:hover {
      background-color: $button-hover-background;
      color: $button-hover-foreground;
    }

    i { // i is for font-awesome icon
      margin: 0 5px 0 0;
      font-weight: bold;
    }
  }

  .context-hr {
    height: 1px;
    border-top: 1px solid $button-foreground;
    margin: 3px 10px;
  }
}