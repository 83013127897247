.minMaxScale {
  display: flex;
  justify-content: center;

  div {
    text-align: center;

    &:first-child, &:last-child {
      width: 15em;
    }
    &:nth-child(2) {
      width: 5em;
    }
  }
}

.input-group {
  &.align-percent {
    input {
      text-align: right;
    }
  }
  &.align-dollar {
    input {
      text-align: left;
    }
  }
}