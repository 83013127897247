.form-group {
  .control-label + .mce-container {
    clear: both;
  }
}

.tinymce-variable {
  cursor: default;
  background-color: #777;
  color: #FFF;
  padding: 4px 8px 2px 8px;
  border-radius: 3px;
  font-weight: bold;
  font-style: normal;
  font-size: .95em;
  display: inline-block;
  line-height: 12px;
}