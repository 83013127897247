.modal-body {
  .daterange {
    .dr-selections {
      position: fixed;
    }
  }
}

.daterange {
  width: 100%;
  clear: both;

  .dr-input {
    height: 34px !important;
    font-size: 14px !important;
    line-height: 1.42857143 !important;
    color: #555 !important;
    background-color: #fff !important;
    background-image: none !important;
    border: 1px solid #ccc !important;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075) !important;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075) !important;
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s !important;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s !important;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s !important;

    .dr-dates {
      .dr-date {
        font-size: 14px !important;
      }
    }

    .dr-presets {
      width: 35px !important;
      height: 34px !important;
    }
  }
  .dr-selections {
    min-width: 100%;
    margin-left: -4px;
    
    .dr-list-item {
      font-size: 14px !important;

      .dr-item-aside {
        display: none;
        font-size: 10px !important;
      }
    }

    .dr-preset-list {
      top: 0 !important;
    }

    .dr-calendar {
      top: 0 !important;
      font-size: 14px !important;

      .dr-range-switcher {
        font-size: 14px !important;
        .dr-switcher {
          height: auto !important;
        }
      }
      .dr-days-of-week-list {
        font-size: 14px !important;
        background-color: white !important;
        color: black !important;

        .dr-day-of-week {
          line-height: 20px !important;
          padding: 5px !important;
          font-weight: bold !important;
        }
      }

      .dr-day-list {
        font-size: 14px !important;
        .dr-day {
          line-height: 20px !important;
          padding: 5px !important;
        }
      }
    }
  }

  &[data-rangestartempty] {
    .dr-input {
      .dr-date.dr-date-start {
        font-size: 1px !important;
        letter-spacing: -1px;
        color: darkGray;

        &:hover {
          color: #2693d5;
        }

        &::before {
          font-size: 14px;
          letter-spacing: normal;
          content: 'No Date';
        }
      }
    }
  }

  &[data-rangeendempty] {
    .dr-input {
      .dr-date.dr-date-end {
        font-size: 1px !important;
        letter-spacing: -1px;
        color: darkGray;

        &:hover {
          color: #2693d5;
        }

        &::before {
          font-size: 14px;
          letter-spacing: normal;
          content: 'No Date';
        }
      }
    }
  }
}