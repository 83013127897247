.input-group-btn {
  .btn {
    height: 34px;

    .input-group-lg & {
      height: 46px;
    }
    
    .input-group-sm {
      height: 30px;
    }
  }
}

.btn-group {
  .btn.hidden:first-child + .btn:not(.hidden) {
    border-top-left-radius: $btn-border-radius-base;
    border-bottom-left-radius: $btn-border-radius-base;

    &.btn-sm {
      border-top-left-radius: $btn-border-radius-small;
      border-bottom-left-radius: $btn-border-radius-small;
    }

    &.btn-lg {
      border-top-left-radius: $btn-border-radius-large;
      border-bottom-left-radius: $btn-border-radius-large;
    }
  }
}

.btn-jma-gold {
  @include button-variant(color-yiq($jma-gold), $jma-gold, $jma-gold);

  &.btn-inset {
    border-style: outset;
    border-width: 3px;
    box-shadow: 4px 4px 4px #555;

    color: #fff;
    background-color: #87754d;
    border-color: #96896e;
    max-width: 100%;

    font-weight: normal;
    white-space: inherit;
    padding: 6px 12px;
    line-height: 1.42857;
    border-radius: 0px;
    font-size: 14px;

    &:hover {
      background-color: #96896e;

      body > footer & {
        text-decoration: underline;
      }
    }

    &:not(:disabled):not(.disabled).active:focus,
    &:not(:disabled):not(.disabled):active:focus {
      outline: 0;
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }
  }
}