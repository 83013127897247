.topaz {
  position: relative;
  display: inline-block;
  padding: 10px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);

  .topaz-container {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .topaz-message {
    margin: 2px 0;
    text-align: center;
    color: #900;
    font-size: 12px;
  }

  button {
    margin: 2px 0;
    padding: 4px 10px;
    color: #fff;
    background-color: #666;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    font-family: sans-serif;
    font-size: 10px;
    text-transform: uppercase;

    &:hover, &:focus {
      background-color: #777;
      border: 1px solid rgba(0, 0, 0, 0.5);
      outline: none;
    }
  }
}