.navbar-brand img{
  height:100%;
}

html, body, body > #wrapper {
  min-height: 100vh;
}

body {
  font-family: 'Roboto', sans-serif;
  position: relative;
  // overflow-y: hidden;
}

#page-content-wrapper {
  // height: calc(100vh - 51px);
  // overflow-y: scroll;
}

#page-content-wrapper-full {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
}

#page-content-wrapper > .container-fluid {
  min-height: 100vh;
  height: 100vh;
}

#errPage h1{
  font-size: 100px;
}

#loginPage h1 {
  font-size: 50px;
}

#loginPage form {
  width: 100%;
  max-width: 300px;
  margin: 40px auto 0 auto;
}

#loginPage form .form-group {
  margin-bottom:5px;
}

label {
  float:left;
  margin-bottom: 0;
}

.navbar-header {
  width: 100%;
}

.navbar-header .logout {
  color: #333;
  margin: 8px 0;
}

.mce-tinymce {
  margin-top: 20px!important;
}

.date {
  width:100%;
}

.hasError {
  border-color: #a94442;
}

.table-striped tbody tr{
  border-bottom: 1px solid #ECECEC;
  background-color: #FFF;
}

tbody tr td {
  .btn-group {
    break-inside: avoid;
    white-space: nowrap;
    
    .btn {
      float: none;
      display: inline-block;
    }
  }
}

.btn-primary-outline {
  color: #0275d8;
  background-color: transparent;
  background-image: none;
  border-color: #0275d8;
}

.btn-success-outline {
  color: #449d44!important;
  background-color: transparent;
  background-image: none;
  border-color: #449d44;
}

.intl-tel-input{
  width:100%;
}

@-webkit-keyframes slide-out {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  30% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}
@-moz-keyframes slide-out {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  30% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}
@keyframes slide-out {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  30% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}
@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-moz-document url-prefix() {
  fieldset { display: table-cell; }
}

.upload {
  min-height: auto;
  border: 3px dashed #CCC !important;
  border-radius: 15px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  margin-top: 20px;
  padding: 20px;
}

.dashboard-buttons {
  display: flex;
  flex-wrap: wrap;

  .btn {
    min-width: 100px;
    min-height: 100px;
    margin: 5px;
    padding-top: 12px;
    font-size: 20px;
    line-height: 24px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    i {
      font-size: 40px;
      margin-bottom: 10px;
      display: block;
    }
  }
}

::-webkit-scrollbar-track{background-color:rgba(0,0,0,0)}::-webkit-scrollbar{width:10px;background-color:rgba(0,0,0,0)}::-webkit-scrollbar-thumb{border:1px solid rgba(0,0,0,0);border-radius:10px;background-color:#939393}::-webkit-scrollbar-corner{background-color:rgba(0,0,0,0)}body,html{scrollbar-arrow-color:#939393;scrollbar-track-color:rgba(0,0,0,0);scrollbar-face-color:#939393;scrollbar-highlight-color:rgba(0,0,0,0);scrollbar-3dlight-color:rgba(0,0,0,0);scrollbar-shadow-color:rgba(0,0,0,0);scrollbar-darkshadow-color:rgba(0,0,0,0)}


.nav-tabs {
  border-bottom: 2px solid #DDD;
  margin-bottom: 10px;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
  border-width: 0;
}

.nav-tabs > li > a {
  border: none;
  color: #666;
}

.nav-tabs > li.active > a, .nav-tabs > li > a:hover {
  border: none;
  color: #4285F4 !important;
  background: transparent;
}

.nav-tabs > li > a::after {
  content: "";
  background: #4285F4;
  height: 2px;
  position: absolute;
  width: 100%;
  left: 0px;
  bottom: -1px;
  transition: all 250ms ease 0s;
  transform: scale(0);
}

.nav-tabs > li.active > a::after, .nav-tabs > li:hover > a::after {
  transform: scale(1);
}

.tab-nav > li > a::after {
  background: #21527d none repeat scroll 0% 0%;
  color: #fff;
}

.tab-pane {
  padding: 15px 0;
}

.tab-content {
  padding: 20px;

  &.tab-content-condensed {
    padding: 0;

    & > .tab-pane {
      padding: 0;
    }
  }
}

#titleBar, .titleBar {
  display: flex;
  align-items: center;

  & > i {
    font-size:25px;
    margin-right: 7px;
  }

  & > strong {
    font-size:25px;
  }

  & > span {
    font-size:12px;
    margin-left:5px;
  }

  & > i + strong + span {
    margin-right: 5px;
  }
  & > i + strong + *:not(span) {
    margin-left: 5px;
  }
}

.sidebar-nav li {
  .fa, .fas, .far, .fal, .fab{
    margin-right: 5px;
  }
}

.flot-chart {
  display: block;
  height: 200px;
}
.flot-chart-content {
  width: 100%;
  height: 100%;
}

.dashboard-metric span{
  color: #848484;
  font-size: 11px;
}
.dashboard-metric strong{
  display: inline-block;
  width: 100%;
  font-size: 28px;
  margin-top: -4px;
}

.jsonpanel{-moz-box-sizing:border-box;-webkit-box-sizing:border-box;box-sizing:border-box;white-space:nowrap;font-family:monospace;line-height:1.4}.jsonpanel *{margin:0;padding:0}.jsonpanel .panel{display:inline-block;vertical-align:top}.jsonpanel .list{list-style-type:none;padding:.5em 0}.jsonpanel ul:before{content:'{'}.jsonpanel ul:after{content:'}'}.jsonpanel ol:before{content:'['}.jsonpanel ol:after{content:']'}.jsonpanel .key{margin-left:1em;font-weight:700}.jsonpanel .pair.simple{max-width:600px;text-indent:-30px;white-space:normal;border:none;margin:0;padding:0 0 0 30px}.jsonpanel .pair .val:after{content:","}.jsonpanel .pair:last-child .val:after{display:none}.jsonpanel .val-inner{display:inline-block;max-width:28em;overflow:hidden;text-overflow:ellipsis;vertical-align:top}.jsonpanel .expander{display:block;text-decoration:none;color:#000;background-color:rgba(211,223,240,0);-moz-transition:background-color .3s ease .5;-o-transition:background-color .3s ease .5;-webkit-transition:background-color .3s ease;-webkit-transition-delay:.5;transition:background-color .3s ease .5}.jsonpanel .expander:hover{background-color:#d3dff0}.jsonpanel .expander .key:before{content:"+";margin-right:.5em}.jsonpanel .expanded{position:relative}.jsonpanel .expanded>.expander .key:before{content:"-"}.jsonpanel .expanded>.expander .val{opacity:0}.jsonpanel .expanded>.panel{margin-left:2em}.jsonpanel .boolean{color:red}.jsonpanel .string{color:green}.jsonpanel .number{color:#00f}.jsonpanel .array .val-inner,.jsonpanel .object .val-inner{color:#a5a5a5}

.striped>.row {
  padding-top: 4px;
  padding-bottom: 4px;
  border-bottom: 1px solid #ECECEC
}
.striped>.row:nth-child(odd){
  /*background-color:#ECECEC;*/
}

.accordion-toggle {
  position: relative;
}
.accordion-toggle::before,
.accordion-toggle::after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  right: -9px;
  width: 10px;
  height: 2px;
  margin-top: -2px;
  background-color: #337ab7;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}
.accordion-toggle::before {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  opacity: 0;
}
.accordion-toggle.collapsed::before {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  opacity: 1;
}
.accordion-toggle.collapsed::after {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.pop-page{
    width: 100%;
    margin: 0 auto;
    max-width: 1000px;
    padding: 25px;
    box-shadow: 0px 1px 10px #CCC;
}
.overlay {
  display: none;
  z-index: 999;
  position: absolute;
  top: -10px;
  right: -20px;
  bottom: -20px;
  left: -20px;
  opacity: 0;
  background-color: #333;
  transition: all .5s linear;
}
.overlay.visible {
  opacity: .5;
}
.overlay div{
  position: fixed;
  right: 20px;
  bottom: 20px;
}
.overlay i{
  font-size: 35px;
  color: #000;
}

.invoice-bar{
  background: #EAEAEA;
  padding:5px;
  border-top:1px solid #CCC;
  margin-top:5px;
  margin-bottom: 5px;
  margin-left: -5px;
  margin-right: -5px;
}

.clickable{
    cursor: pointer;
}

a.btn.disabled.pointer-events{
  pointer-events: all;
}

#sndtinygallery,
#sndtinylinkgallery {
  width: 85% !important;
  height: 90% !important;
  left: 7.5% !important;
  top: 5% !important;
}

#sndtinygallery .mce-reset,
#sndtinylinkgallery .mce-reset {
  height: 100% !important;
}

#sndtinygallery .mce-reset #sndtinygallery-body,
#sndtinylinkgallery .mce-reset #sndtinylinkgallery-body {
  width: 100% !important;
  height: calc(100% - 90px) !important;
}

#sndtinygallery .mce-reset .mce-container.mce-foot,
#sndtinylinkgallery .mce-reset .mce-container.mce-foot {
  width: 100%;
}

#gallery-images.dz-max-files-reached > .dz-message {
  display: none;
}
#gallery-images .dropzone-previews .dz-preview {
  padding: 5px;
  width: auto;
}
#gallery-images .dropzone-previews .dz-preview.dz-selected {
  border: 2px solid black;
  border-radius: 10px;
  background-color: #6495ed;
}

.gallery-sel-image-preview {
  height: calc(((25vw - 60px) / 4) * 3);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid black;
}

.log-viewer .updated-fields {
  display: none;
}

.log-viewer .updated-fields th {
  padding-right: 5px;
}

.template { display: none; }
td, th {
  p:first-child { margin-top: 0; }
  p:last-child { margin-bottom: 0; }
}

*[data-readmore] {
  transition: height 100ms;
  overflow: hidden;
}
// .readmore {
//   &:not([aria-expanded=true]) {
//     overflow: hidden;
//   }
//   &.rm-2l:not([aria-expanded=true]) {
//     max-height: 2.9em;
//   }

//   &.rm-3l:not([aria-expanded=true]) {
//     max-height: 3.9em;
//   }
// }

tr, td, th {
  &.deleting-row {
    background-color: rgba(255, 0, 0, 0.57);
  }
}

#dynamicFields { min-height: 54px; }

.dl-scan {
  cursor: pointer;
}

.no-ce-border:focus {
  outline: 0px solid transparent;
}

.fa.click-check,
.far.click-check,
.fab.click-check,
.fas.click-check,
.fal.click-check {
  font-size: 23px;
  cursor: pointer;
}
.fa.click,
.far.click,
.fab.click,
.fas.click,
.fal.click {
  cursor: pointer;
}

#editInventory {
  #primaryImage {
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    border: 1px solid black;
    border-radius: 4px;
    width: 100%;
  }
}

tbody, thead, tfoot {
  #auctionRingTable > &, #auctionLocationTable > & {
    & > tr {
      & > td {
        &:first-child {
          padding-left: 0;
        }
      }
    }
  }
}
// #auctionRingTable {
//   & > tbody {
//     & > tr:not(:last-child), & > tr:only-child {
//       button { display: none; }
//     }
//   }
// }

.intl-tel-input .country-list {
  position: fixed !important;
  z-index: 2147483647 !important;
}

.table-responsive-sm {
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.btn-grp {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding-left: 5px;
}

@media(max-width: 992px) {
  .pull-right {
    float: none!important;
  }

  .btn-grp {
    padding-top: 5px;
  }
}

@media(max-width: 1200px) {

  .table-responsive-sm {
    width: 100%;
    max-width: 100%;
    margin-bottom: 15px;
    overflow-x: auto;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}

/*@-webkit-keyframes blinker { 50% { opacity: 0; } }
@-moz-keyframes blinker { 50% { opacity: 0; } }
@-o-keyframes blinker { 50% { opacity: 0; } }*/
@keyframes blinker { 50% { opacity: 0; } }
.blink { animation: blinker 1s linear infinite; }
.has-license { background-color: #dff0d8; }

ul.nav.nav-tabs {
  li {
    span.badge, span.label {
      margin-left: .5em;
      vertical-align: middle;
    }
  }
}

#scanning-stream {
  video {
    max-height: 99%;
    max-width: 100%;
    width: 100%;
  }
  canvas {
    display: none;
  }
}

#primaryDlImage {
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  border: 1px solid #000;
  border-radius: 4px;
  width: 100%;
  min-height: 162px;
}

.control-label {
  .eml-add-link {
    margin-left: 1em;
    font-weight: normal;
  }
}

#map-flex-fill {
  height: 100%;
  display: flex;
  flex-direction: column;

  #googleMapToDisplay {
    flex-grow: 1;

    h4.coord-hover {
      margin-bottom: 0;

      & ~ div:last-child {
        margin-top: 10px;
      }
    }
  }
}

.signature-pad {
  padding-top: 5px;
  canvas {
    outline: 1px solid black;
    width: 100%;
    height: 15em;
  }
}

img.pswp__img { background-color: white; }

.text-uc { text-transform: uppercase; }

.markForEdit {
  margin-left:5px;
  cursor: pointer;
}

.sort-grip::before {
  font-family: 'Font Awesome 5 Pro';
  content: '\f142';
  cursor: pointer;
}

$comment-border: #ddd;
$comment-border-width: 3px;
.comments-form {
  & + .comments-container {
    margin-top: 1rem;
  }

  button { margin-left: 1rem; }
}
.comments-container {
  display: flex;
  flex-direction: column;

  .comment-bubble {
    min-width: 50%;
    max-width: 75%;
    position: relative;
    border: $comment-border-width solid $comment-border;
    border-radius: $comment-border-width * 2;
    padding: 1rem;

    &::before {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      bottom: auto;
      border: ($comment-border-width * 2.5) solid;
    }
    &::after {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      bottom: auto;
      border: (($comment-border-width * 2.5) * 0.6) solid;
    }

    &.left {
      margin-left: $comment-border-width * 2;
      align-self: flex-start;

      &::before {
        left: 0 - ($comment-border-width * 6);
        right: auto;
        top: $comment-border-width;
        border-color: $comment-border $comment-border transparent transparent;
      }
      &::after {
        left: 0 - ($comment-border-width * 3);
        right: auto;
        top: ($comment-border-width * 2);
        border-color: white white transparent transparent;
      }
    }
    &.right {
      align-self: flex-end;
      margin-right: $comment-border-width * 2;

      &::before {
        left: auto;
        right: 0 - ($comment-border-width * 6);
        top: $comment-border-width;
        border-color: $comment-border transparent transparent $comment-border;
      }
      &::after {
        left: auto;
        right: 0 - ($comment-border-width * 3);
        top: ($comment-border-width * 2);
        border-color: white transparent transparent white;
      }
    }

    & + .comment-bubble {
      margin-top: .5rem;
    }

    .comment-text {
      white-space: pre-line;
    }
  }
}

.form-group.col-sm-12.blog-tag {
  padding-right: 0;
  padding-left: 0;
  margin-bottom: 0;
}

tr.completed-expense {
  td, th {
    font-style: italic;
  }
}

#location-list {
  .panel {
    margin-left: 15px;
    margin-right: 15px;
    min-width: 230px;

    display: flex;
    flex-direction: column;

    .panel-heading {
      text-align: center;
    }

    .panel-body {
      flex: 1 1 auto;

      hr {
        margin-top: .25em;
        margin-bottom: .25em;
      }
    }

    .panel-footer {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.env-tag {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #b8daff; /* border */
  pointer-events: none;
  clip-path: polygon(0 0, 100% 0, 90% 100%, 10% 100%);
  width: 10em;
  height: 1.5em;
  z-index: 999999;
  opacity: 0.8;
  font-weight: bold;
  font-family: 'Courier New', Courier, monospace;
  line-height: 1.5;
  font-size: 14px;

  &::before {
    content: 'development';
    color: #004085;
    background-color: #cce5ff;
    clip-path: polygon(0 0, 100% 0, 90% 100%, 10% 100%);
    position: absolute;
    top: 0;
    left: 1px;
    width: calc(10em - 2px);
    height: calc(1.5em - 1px);
    text-align: center;
  }

  &.danger {
    background-color: #f5c6cb;

    &::before {
      color: #721c24;
      background-color: #f8d7da;
    }
  }

  &:not([data-env*="dev"]) {
    &::before {
      content: attr(data-env);
    }
  }
}