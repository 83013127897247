#editAuctionWebsite {
  #sales-reps {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-top: 5px;
    margin-bottom: 5px;

    .rep {
      flex: 0 0 auto;
      margin: 5px;
      min-width: 15rem;
      max-width: 275px;
      width: 275px;
      position: relative;

      .rep-options {
        position: absolute;
        padding: 5px;
        top: 5px;
        right: 5px;
        color: #000;
        background: #fff;
        border-radius: 50px;
        box-shadow: 0 0 3px rgba(0, 0, 0, .25);
        z-index: 1050;
      }
      .rep-menu {
        top: 35px;
        right: 6px;
        left: auto;
        min-width: auto;
      }

      img {
        max-width: 100%;
        height: auto;
      }

      &.ui-sortable-placeholder {
        visibility: visible !important;
        border: 2px dashed #808080;
      }

      .rep-title {
        font-weight: bold;
      }

      .details {
        display: flex;
        justify-content: space-between;
        line-height: 1em;
        margin-top: .25em;
      }

      .email {
        font-size: .85em;
      }

      &.rep--large {
        .show-small {
          display: none;
        }
      }

      &.rep--small {
        max-width: 180px;
        min-width: 180px;
        width: 180px;
        .rep-title, .details {
          font-size: 0.85rem;
        }

        .details {
          flex-direction: column;
        }

        .email {
          font-size: 0.8rem;
        }

        .show-large {
          display: none;
        }
      }
    }
  }
}