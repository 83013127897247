$icon-font-path: '/fonts/bootstrap/';

$jma-gold: #87754d !default;

@import 'bootstrap-sass/assets/stylesheets/_bootstrap.scss';

@function color-yiq($color, $dark: #212529, $light: #fff) {
  $r: red($color);
  $g: green($color);
  $b: blue($color);

  $yiq: (($r * 299) + ($g * 587) + ($b * 114)) / 1000;

  @if ($yiq >= 150) {
    @return $dark;
  } @else {
    @return $light;
  }
}

@import 'input-groups';
@import 'alerts';
@import 'buttons';


.mb-5 {
  margin-bottom: 5px;
}