.column-chooser {

  &.overflow {
    ul.dropdown-menu {
      position: fixed;
      z-index: 50;
    }
  }

  ul.dropdown-menu {
    padding: 0;

    li.selected {
      a {
        background-color: #313b74;
        color: #7c84ae;

        &:hover {
          background-color: #182257;
        }
      }
    }

    li:first-child {
      a {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
    }

    li:last-child {
      a {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

}