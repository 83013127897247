.field-search {
  position: relative;
  width: 100%;
  margin-bottom: 5px;

  .fa-search, .fa-new-search {
    color: #979797;
    font-size: 1.5rem;
    left: 5px;
    position: absolute;
    top: 2px;
  }
  input {
    appearance: none;
    border: none;
    border-bottom: 2px solid #c5c5c5;
    font-size: 1.4rem;
    padding: 0 0 5px;
    text-indent: 25px;
    width: 100%;

    &:focus {
      border-bottom-color: #858585;
      outline: none;
    }
  }
}

.field-scroll {
  overflow-y: auto;
  max-height: 35em;
  margin: 0 -15px -15px -15px;
  padding: 0 15px;
}

ul.field-list {
  list-style-type: none;
  padding: 0;

  li + li {
    margin-top: 5px;
  }

  .btn-group {
    width: 100%;
    display: flex;
  }

  button.addField {
    &::after {
      content: ''
    }
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    overflow-x: hidden;

    span.field-name {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.field-textbox {
  position: relative;
  .box {
    display: inline-block;
    position: absolute;
    border: 1px solid black;
    height: 50%;
    width: calc(100% - 5px);
    top: 25%;
  }
  i.fa, i.far, i.fal, i.fas, i.fab {
    margin-right: 5px;
  }
}

.field-textarea {
  position: relative;
  .box {
    display: inline-block;
    position: absolute;
    border: 1px solid black;
    height: calc(100% + 4px);
    width: calc(100% - 5px);
    top: -2px;
  }
  i.fa, i.far, i.fal, i.fas, i.fab {
    margin-right: 5px;
  }
}

.panel.dynamic-field {
  .panel-heading {
    .panel-title {
      display: flex;
      justify-content: space-between;

      & > div > a { color: inherit; }
    }
  }
}

#selectAField {
  ul {
    list-style-type: none;
    padding-left: 0;
    column-count: 3;

    li {
      label {
        float: none;
      }
    }
  }
}